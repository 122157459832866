.card-header {
    background-color: #FF6A00 !important;
    border-color: #FF6A00 !important;
    outline: 0 !important;
    box-shadow: 3px 3px 10px 0px #F5C469 !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}
.form-check-input {
    border-color: #000000 !important;
    box-shadow: 0px 0px 0px 0px !important;
}

.list-group-item {
    text-align: left !important;
    font-size: 13px !important;
    padding: 4px !important;
}

.teksts {
    white-space: nowrap !important;
    text-align: center !important;
    font-weight: bold !important;
    color: white !important;
}

.rounded-3 {
    min-width: 130px !important;

}

.card-header:first-child {
    height: 30% !important;
}

.card-body {
    padding: 0px !important;
}




@media only screen and (max-width: 988px) {
    .list-group-item {
        white-space: nowrap !important;
        text-align: left !important;
        font-size: 13px !important;
        padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
    }
    .rounded-3 {
    }
}
