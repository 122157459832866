.App {
  text-align: center;
  font-family: "Roboto Regular", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn {
  border-color: #FF6A00 !important;
  border-width : 0 !important;
  position: relative !important;
  background-color: #FF6A00 !important;
  border-radius: 4em !important;
  font-size: 16px !important;
  color: white !important;
  padding: 0.8em 1.8em !important;
  cursor:pointer !important;
  user-select:none !important;
  text-align: center !important;
  text-decoration: none !important;
  cursor: pointer !important;
  transition-duration: 0.4s !important;
  -webkit-transition-duration: 0.4s !important; /* Safari */
}

.btn:hover {
  transition-duration: 0.1s !important;
  background-color: #F5C469 !important;
}

.btn:after {
  content: "" !important;
  display: block !important;
  position: absolute !important;
  border-radius: 4em !important;
  left: 0 !important;
  top:0 !important;
  width: 100% !important;
  height: 100% !important;
  opacity: 0 !important;
  transition: all 0.5s !important;
  box-shadow: 0 0 10px 40px white !important;
}

.btn:active:after {
  box-shadow: 0 0 0 0 #F5C469 !important;
  position: absolute !important;
  border-radius: 4em !important;
  left: 0 !important;
  top:0 !important;
  opacity: 1 !important;
  transition: 0s !important;
}


.btn:active {
  top: 1px !important;
}


.datums {
  text-align: left !important;
}

.text-muted {
  padding-top: 5px !important;
}

.fw-normal {
  color: black !important;
  font-weight: bold !important;
}
