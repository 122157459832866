.card-header {
    background-color: #FF6A00;
    color: white !important;
    height: 50%;
    font-weight: bold !important;
    margin-top: 5px;


}

.tab-value {
    font-size: 13px !important;
}
.fw-normal .h4 {
    white-space: normal !important;
}

.kaste {
    min-width: 252.667px;
    max-width: 830px;
}

.th {
    align-items: center;
}
